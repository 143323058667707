<template>
    <div>
    <div class="box-curves">
    <v-card-title>
     Capteurs Environnements
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="capteur"
      :search="search"
    >
      <template v-slot:item="{ item }">
          <tr :class="{'highlight': item.updated}">
            <td v-for="header in headers" :key="header.value">
              <div v-if="header.value === 'action'">
                <!-- Slot personnalisé pour l'action avec l'image -->
              </div>
              <div v-else>
                <!-- Affichage de la valeur de l'élément pour les cellules qui ne sont pas l'action -->
                {{ item[header.value] }}
              </div>
            </td>
          </tr>
        </template>
    </v-data-table>
  </div>
    </div>
</template>

<script>
import { getDataLastId, getDurationCapteurs, getStockage } from '../functions/get-data'
import { formatDuration } from '../functions/format';
import { switchToken } from '../functions/test-token';
import moment from 'moment';
moment.locale('en');
export default {
	data(){
		return{
			search: '',
            capteur_id_name :[],
            capteur_id: [],
            val_ia: 0,
            capteur: [],
            headers: [
            {
                text: 'Capteur',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Présence', value: 'wifi' },
            { text: 'Stockage', value: 'stockage' },
            { text: 'Durée utilisation', value: 'durée'},
            { text: 'Position', value: 'position' },
            { text: 'Température (°C)', value: 'temperature'},
            { text: 'Luminosité (Lux)', value: 'brightness'},
            { text: 'Humidité (RH%)', value: 'humidity'},
            { text: 'Dernière utilisation', value: 'last_used' }
            ],
            interval1: null,
            interval2: null,
            id_memory: [],
		}
	},
	async mounted(){
        const GetCapteurs = await Promise.all([this.getCapteursAvailable()]);
        if (GetCapteurs) {
          if(this.capteur_id !== null){
            
            this.CreateTable();
            this.getStockageCapteurs();
            this.getDurationCapteurs();
            this.getDatafor();
            this.interval1 = setInterval(this.getDatafor, 4000);
            this.interval2 = setInterval(() => {
              this.getStockageCapteurs();
              this.getDurationCapteurs();
            // Ajoutez autant d'appels de fonctions que nécessaire
            }, 50000);
          }
        }
	},
  methods:{
    async getCapteursAvailable(){
          const user = JSON.parse(localStorage.getItem('user'))
          const accessToken = localStorage.getItem('accessToken');
          const email = user.email
          const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
          const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          });
          return fetch(url, { headers: headers })
            .then(res => {
              if (res.status === 401) {
                switchToken().then(() => {
                  // Refaites la requête avec le nouveau token
                  this.getCapteursAvailable();
                });
              }
              return res.text();
            })
            .then(async (result) => {
                const data = JSON.parse(result);
                console.log(data)
                this.capteur_id = data.capteur_environnement;
                const capteur_name = data.environnement_renamed;
                if(this.capteur_id !== null){
                  for(let i=0 ; i< this.capteur_id.length ; i++){
                    if(capteur_name[i] == "noname"){
                        this.capteur_id_name.push(this.capteur_id[i])
                    }else{
                        this.capteur_id_name.push(capteur_name[i])
                    }
                  }
                }
            })
            .catch(error => {
                console.log(error)
            });
    },
    
    async CreateTable(){
        for(let i = 0; i < this.capteur_id.length; i++){
        this.capteur.push(
            {
            tag: this.capteur_id[i],
            name: this.capteur_id_name[i],
            wifi: '',
            stockage: 0,
            durée: 0,
            position : 0,
            temperature: 0,
            brightness: 0,
            humidity: 0,
            last_used: 0,
            updated: false,
            id_memo : 0,
        },
        )
        }
    },
    async getStockageCapteurs() {
        let results = [];
        for (let i = 0; i < this.capteur_id.length; i++) {
            const tag_id = this.capteur_id[i];
            const result = await getStockage(tag_id)
            if (result) {
              results.push(result);
            }
        }
        for (let i = 0; i < results.length; i++) {
            let duration = results[i] ? results[i][0].table_size : '';	
            // Affectation de la durée formatée à la propriété 'durée' de l'objet correspondant
            this.capteur[i].stockage = duration;
        }
        this.show = 1;
    },
    async getDurationCapteurs() {
			for (let i = 0; i < this.capteur_id.length; i++) {
				const tag_id = this.capteur_id[i];
				const total_duration  = await getDurationCapteurs(tag_id)
				this.capteur[i].durée = await formatDuration(total_duration)
			}
		},
		
    async getDatafor(){
        for(let i = 0; i < this.capteur.length; i++){
          const data = await getDataLastId(this.capteur[i].tag);
          this.capteur[i].last_used = this.formatDate(data[0].received_on);
          this.capteur[i].temperature = data[0]?.temp_tag/100;
          this.capteur[i].brightness = data[0]?.tag_brightness;
          this.capteur[i].humidity = data[0]?.tag_humidity;
          this.capteur[i].position = data[0]?.pos_y_tag*8 + data[0]?.pos_x_tag + 1;
          const previousId = this.capteur[i].id_memo || 0;
          if (previousId) {
            const isNewData = data[0].id > previousId;
            this.capteur[i].wifi = isNewData ? 'présent' : 'absent';
            this.capteur[i].updated = isNewData;
          }
          this.capteur[i].id_memo = data[0].id
        }
        await this.moveUpdatedTagsToTop();
    },
    async moveUpdatedTagsToTop() {
      const updatedTags = [];
      const notUpdatedTags = [];
      this.capteur.forEach(tag => {
        if (tag.updated) {
          updatedTags.push(tag);
        } else {
          notUpdatedTags.push(tag);
        }
      });
      this.capteur = [...updatedTags, ...notUpdatedTags];
      
    },
    formatDate(date) {
        return moment(date).format('LL, LT');
    },
  },
  beforeDestroy() {
      clearInterval(this.interval1);
      clearInterval(this.interval2);
  },
}
</script>
