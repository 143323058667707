<template>
  <div>
  <div class="box-curves">
    <v-card-title >
      Implants
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search2"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers_tag"
      :items="tags"
      :search="search2"
    >
      <template v-slot:item="{ item }">
        <tr :class="{'highlight': item.updated}">
          <td v-for="header in headers_tag" :key="header.value">
            <!-- Utilisez l'attribut 'v-if' pour vérifier si la cellule actuelle doit inclure le slot 'item.action' -->
            <div v-if="header.value === 'action'">
              <!-- Slot personnalisé pour l'action avec l'image -->
              <v-img src="../assets/illustrations/passeport.png" class="img-passeport" @click="getDataPasseport(item.name)"></v-img>
            </div>
            <div v-else>
              <!-- Affichage de la valeur de l'élément pour les cellules qui ne sont pas l'action -->
              {{ item[header.value] }}
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>

  </div>
  <v-dialog v-model="dialog2" max-width="400">
			<v-card class="form-card mb100 mt100">
          <div class="form-header">
            <h2>Passeport</h2>
          </div>
          <form class="form" @submit.prevent="UpdatePasseport">
            <v-row>
              <v-col cols="12">
                <label>Espèce</label>
                <input type="text"  autocomplete="off" v-model="espèce">
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>Sexe</label>
                <input type="text"  autocomplete="off" v-model="sexe">
              </v-col>
            </v-row>
             <v-row>
              <v-col cols="12">
                <label>Date de naissance</label>
                <input type="date"  autocomplete="off" v-model="naissance">
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>Projet associé</label>
                <input type="text" autocomplete="off" v-model="projets">
              </v-col>
            </v-row>
            <v-row>
               <v-col cols="12" style="display: flex; justify-content: right;">
                  <button type="submit" class="submit-button" @mouseenter="hover = true" @mouseleave="hover = false" >
                    <v-icon>mdi-content-save-edit-outline</v-icon>
                    <!-- Afficher le texte "Save" quand hover est true -->
                    <span v-if="hover" >  Save</span>
                  </button>
                </v-col>
            </v-row>
          </form>
        </v-card>
		</v-dialog>

  </div>
</template>

<script>
import { getDataLastId, getDurationCapteurs, getStockage } from '../functions/get-data'
import { formatDuration, formatDate } from '../functions/format';
import { switchToken } from '../functions/test-token';

import moment from 'moment';
moment.locale('en');

  export default {
    data () {
      return {
        hover: false,
        search2: '',
        tag_id: [],
        tag_id_name: [],
        dialog2: false,
        headers_tag: [
          {
            text: 'Implants',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Présence', value: 'wifi' },
          { text: 'Stockage', value: 'stockage'},
          { text: 'Durée utilisation', value: 'durée'},
          { text: 'Position', value: 'position' },
          { text: 'Température (°C)', value: 'temperature' },
          { text: 'Fréquence cardiaque (bpm)', value: 'bpm' },
          { text: 'Taux Oxygène (%)', value: 'spo2' },
          { text: 'Accélérometre (g)', value: 'acceléromètre' },
          { text: 'Dernière utilisation', value: 'last_used', sortable: true },
          { text: 'Passeport', value: 'action' },
        ],
        projets: '',
        espèce: '',
        naissance: '',
        sexe: '',
        nameInput: '',
        val_ia : 0,
        tag_name: '',
        tags: [],
        id_memory: [],
        interval2: null,
        interval3: null,
      }
    },
    async mounted(){
        await this.getTagAvailable();
        this.CreateTable();
        await this.getStockage();
        await this.getDuration();
        this.interval2 = setInterval(this.getDatafor, 2000);
        this.interval3 = setInterval(() => {
          this.getStockage();
          this.getDuration();
          // Ajoutez autant d'appels de fonctions que nécessaire
        }, 50000);
    },
    methods:{
        CreateTable(){
          if (this.tag_id && this.tag_id.length > 0) {
            for(let i = 0; i < this.tag_id.length; i++){
              this.tags.push(
                {
                tag : this.tag_id[i],
                name: this.tag_id_name[i],
                wifi: '',
                stockage: 0,
                durée: 0,
                position: 0,
                temperature: 0,
                bpm: 0,
                spo2: 0,
                temperaturef: 0,
                acceléromètre: 0,
                last_used: 0,
                updated : false,
              },
              )
            }
          }
        },
      
        async moveUpdatedTagsToTop() {
          const updatedTags = [];
          const notUpdatedTags = [];
          this.tags.forEach(tag => {
            if (tag.updated) {
              updatedTags.push(tag);
            } else {
              notUpdatedTags.push(tag);
            }
          });
          this.tags = [...updatedTags, ...notUpdatedTags];
        },

      async getDatafor(){
        for (let i = 0; i < this.tags.length; i++) {
          const tag_id = encodeURIComponent(this.tags[i].tag);
          const data = await getDataLastId(tag_id);
          if (!data[0]) {
            console.warn(`No data found for tag: ${this.tag_id[i]}`);
            continue;
          }
          const axe_x = (data[0]?.acc_x_tag?.[0] || 0).toFixed(5);
          const axe_y = (data[0]?.acc_y_tag?.[1] || 0).toFixed(5);
          const axe_z = (data[0]?.acc_z_tag?.[2] || 0).toFixed(5);
          this.tags[i].last_used = formatDate(data[0].received_on);
          // if(tag_id == 'imp_882f4b1e032502e0'){
          //   console.log(data[0].received_on)
          //   console.log(formatDate(data[0].received_on))
          // }
          this.tags[i].temperature = data[0]?.temp_tag / 100;
          this.tags[i].temperaturef = (data[0]?.temp_tag / 100 * 1.8 + 32).toFixed(2);
          this.tags[i].bpm = data[0]?.bpm;
          this.tags[i].spo2 = data[0]?.spo2;
          this.tags[i].position = data[0]?.pos_y_tag * 8 + data[0]?.pos_x_tag + 1;
          this.tags[i].acceléromètre = `${axe_x}  ,  ${axe_y}  ,  ${axe_z}`;
          const previousId = this.tags[i].id_memo || 0; // Assurez-vous que previousId a une valeur par défaut
          this.tags[i].id_memo = data[0]?.id; // Mettez à jour l'ID mémorisé avec le nouvel ID
          if (previousId) {
            const isNewData = data[0].id > previousId;
            this.tags[i].wifi = isNewData ? 'présent' : 'absent';
            this.tags[i].updated = isNewData;
          }
        }
        // Déplacer les tags mis à jour en haut du tableau
        await this.moveUpdatedTagsToTop();
      },

      async getStockage() {
				let results = [];
        if (this.tag_id && this.tag_id.length > 0) {
				for (let i = 0; i < this.tag_id.length; i++) {
					const tag_id = this.tag_id[i];
					const result = await getStockage(tag_id)
					results.push(result);
				}
				for (let i = 0; i < results.length; i++) {
					let duration = results[i] ? results[i][0].table_size : '';	
					// Affectation de la durée formatée à la propriété 'durée' de l'objet correspondant
					this.tags[i].stockage = duration;
				}
      }
			},

      async getDuration() {
        if (this.tag_id && this.tag_id.length > 0) {
        for (let i = 0; i < this.tag_id.length; i++) {
          const tag_id = this.tag_id[i];
          const total_duration = await getDurationCapteurs(tag_id)
          this.tags[i].durée = await formatDuration(total_duration)
        }
			}
		},

    async getTagAvailable(){
        	const user = JSON.parse(localStorage.getItem('user'))
          const accessToken = localStorage.getItem('accessToken');
          const email = user.email
          const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
          const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          });
          return fetch(url, { headers: headers })
            .then(res => {
              if (res.status === 401) {
                switchToken().then(() => {
                  // Refaites la requête avec le nouveau token
                  this.getTagAvailable();
                });
              }
              return res.text();
            })
        .then(async (result) => {
            const data = JSON.parse(result);
            this.tag_id = data.tag_attributed
            this.tag_name = data.tag_renamed
            if (this.tag_id && this.tag_id.length > 0) {
              for(let i=0 ; i< this.tag_id.length ; i++){
                if(this.tag_name[i] == "noname"){
                    this.tag_id_name.push(this.tag_id[i])
                }else{
                    this.tag_id_name.push(this.tag_name[i])
                }
              }
            }
        })
        .catch(error => {
            console.log(error)
        });
    },
    
    async getDataPasseport(tag_id){
      const tag_name = this.tag_id[this.tag_id_name.indexOf(tag_id)]
      console.log(tag_name)
      const accessToken = localStorage.getItem('accessToken');
      const url = this.$api.getRESTApiUri() + `/get-data/passeport/${tag_name}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      });
      try {
        const res=await fetch(url, { headers: headers });
        if(res.status===401) {
          switchToken().then(() => {
            // Refaites la requête avec le nouveau token
            this.getDataPasseport(tag_id);
          });
        }
        const result_3=await res.text();
        const data=JSON.parse(result_3);
        this.projets=data[0]?.projet;
        this.sexe=data[0]?.sexe;
        this.naissance= data[0]?.naissance?.substr(0, 10);
        this.espèce=data[0]?.espèce;
        this.tag_name = tag_name;
        this.dialog2=true;
      } catch(error) {
        console.log(error);
      }
    },
    
    async UpdatePasseport(){
      const accessToken = localStorage.getItem('accessToken');
      const url = this.$api.getRESTApiUri() + `/update-data/passeport`
      return fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify({
            espèce: this.espèce,
            naissance: this.naissance,
            projet: this.projets,
            sexe : this.sexe,
            tag_name : this.tag_name,
          }),
          
      })
        .then(res => {
        if (res.status === 401) {
          switchToken().then(() => {
            // Refaites la requête avec le nouveau token
            this.UpdatePasseport();
          });
        }
        return res.text();
      })
      .then(json => console.log(json),
          this.dialog2 = false,
      );		
    },
    },
    beforeDestroy() {
      clearInterval(this.interval2);
      clearInterval(this.interval3);
    },
  }
</script>

<style scoped>

.highlight {
  position: relative;
  z-index: 1;
}

.highlight::after {
  content: "";
  background-color: var(--vertfonce);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  animation: fadeOutOpacity 2s infinite;
}

@keyframes fadeOutOpacity {
  0%, 100% {
    opacity: 0.5;
  }
  25%, 75% {
    opacity: 0.2;
  }
}



.img-passeport{
  width: 40px;
  height: auto;
  cursor: pointer
}
.form-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
}
.updated-row {
  background-color: green; /* Utilisez la nuance de vert que vous préférez */
}
.form-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.form-header h2 {
  margin-left: 10px;
  color: var(--bleu)
}
.form label {
  width: 100%;
  font-family: Roboto, Regular;
  font-size: 20px;
  color: var(--bleu);
}

.form input,
.form textarea {
  width: 100%;
  border: 1px solid var(--grisclair);
	border-radius: 5px;
  background-color: white;
  height: 35px;
  padding: 10px;
  margin-bottom: 15px;
}

.form textarea {
  resize: vertical;
}


.submit-button {
  color: white !important;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button span{
  color: black;
}


@keyframes highlightAnimation {
  from { background-color: var(--bleu); }
  to { background-color: transparent; }
}
</style>