let activityTimer;
let pingInterval;

export default {
  mounted() {
    this.setupActivityTracker();
    this.setupPingToServer();
  },

  beforeDestroy() {
    this.cleanupActivityTracker();
    this.cleanupPingToServer();
  },

  methods: {
    setupActivityTracker() {
      this.resetActivityTimer();
      window.addEventListener('mousemove', this.resetActivityTimer);
      window.addEventListener('keypress', this.resetActivityTimer);
      window.addEventListener('scroll', this.resetActivityTimer);
      // Ajoutez d'autres événements si nécessaire
    },

    cleanupActivityTracker() {
      clearTimeout(activityTimer);
      window.removeEventListener('mousemove', this.resetActivityTimer);
      window.removeEventListener('keypress', this.resetActivityTimer);
      window.removeEventListener('scroll', this.resetActivityTimer);
      // Retirez tous les écouteurs d'événements ajoutés
    },

    async resetActivityTimer() {
      clearTimeout(activityTimer);
      activityTimer = setTimeout(async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        await this.disconnectUser(user.email);
        localStorage.clear('user');
        await this.$router.push({name:'Login'})
        window.alert('Vous avez été inactif pendant 30 minutes.');
        // supprimer l'utilisateur dans le localstorage
        // rédiriger vers la page login
      }, 30 * 60 * 1000); // 30 minutes
    },

    
    setupPingToServer() {
      pingInterval = setInterval(this.pingServer, 15 * 1000); // 15 secondes
    },

    cleanupPingToServer() {
      clearInterval(pingInterval);
    },

    async pingServer() {
      // Exemple de fonction pour envoyer un ping au serveur
      // Remplacez par votre logique d'appel API
      const timestamp = new Date().toISOString();
      const url = this.$api.getRESTApiUri() + `/update-date-activity`
				return fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: 'lemathis14@gmail.com',
						last_active: timestamp
					}),
				})
        .then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
				})
				.catch((error) => {
					console.log(error)
				});
    },

    async disconnectUser(user){
      console.log(user)
      const url = this.$api.getRESTApiUri() + `/user/delete-session`;
				return fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							email: user,
						}),
				})
				.then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
				});
    },
    
  }
};
