<template>
    <div>
      <div class="box-curves">
        <v-card-title>
        Capteurs vibrations
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="capteur"
          :search="search"
        >
          <template v-slot:item="{ item }">
              <tr :class="{'highlight': item.updated}">
                <td v-for="header in headers" :key="header.value">
                  <div v-if="header.value === 'action'">
                    <!-- Slot personnalisé pour l'action avec l'image -->
                  </div>
                  <div v-else>
                    <!-- Affichage de la valeur de l'élément pour les cellules qui ne sont pas l'action -->
                    {{ item[header.value] }}
                  </div>
                </td>
              </tr>
            </template>
        </v-data-table>
      </div>
    </div>
</template>

<script>
import { getDataLastId, getDurationCapteurs, getStockage } from '../functions/get-data'
import { formatDuration } from '../functions/format';
import { switchToken } from '../functions/test-token';
import moment from 'moment';
moment.locale('en');
export default {
	data(){
		return{
			search: '',
            capteur_id_name :[],
            capteur_id: [],
            val_ia: 0,
            capteur: [],
            headers: [
            {
                text: 'Capteur',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Présence', value: 'wifi' },
            { text: 'Stockage', value: 'stockage' },
            { text: 'Durée utilisation', value: 'durée'},
            { text: 'Position', value: 'position' },
            { text: 'Vibrations (g)', value: 'vibrations'},
            { text: 'Dernière utilisation', value: 'last_used' }
            ],
            interval1: null,
            interval2: null,
            id_memory: [],
		}
	},
	async mounted() {
        const GetCapteurs = await Promise.all([this.getCapteursAvailable()]);
        if (GetCapteurs) {
            if (Array.isArray(this.capteur_id) && this.capteur_id.length > 0) { // Vérifiez si le tableau est défini et non vide
                
                this.CreateTable();
                this.getStockageCapteurs();
                this.getDurationCapteurs();
                this.getDatafor();
                this.interval1 = setInterval(this.getDatafor, 3000);
                this.interval2 = setInterval(() => {
                    this.getStockageCapteurs();
                    this.getDurationCapteurs();
                    // Ajoutez autant d'appels de fonctions que nécessaire
                }, 50000);
            }
        }
    },

    methods:{
    async getCapteursAvailable(){
            const user = JSON.parse(localStorage.getItem('user'))
            const accessToken = localStorage.getItem('accessToken');
            const email = user.email
            const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
            const headers = new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            });
            return fetch(url, { headers: headers })
              .then(res => {
                if (res.status === 401) {
                  switchToken().then(() => {
                    // Refaites la requête avec le nouveau token
                    this.getCapteursAvailable();
                  });
                }
                return res.text();
              })
            .then(async (result) => {
                const data = JSON.parse(result);
                
                this.capteur_id = data.capteur_vibration;
                const capteur_name = data.vibration_renamed;
                if(this.capteur_id !== null){
                    for(let i=0 ; i< this.capteur_id.length ; i++){
                  if(capteur_name[i] == "noname"){
                      this.capteur_id_name.push(this.capteur_id[i])
                  }else{
                      this.capteur_id_name.push(capteur_name[i])
                  }
                }
                }
                
            })
            .catch(error => {
                console.log(error)
            });
    },
    
    async CreateTable(){
      for(let i = 0; i < this.capteur_id.length; i++){
        this.capteur.push(
          {
            name: this.capteur_id_name[i],
            wifi: '',
            stockage: 0,
            durée: 0,
            position:0,
            vibrations: 0,
            last_used: 0,
            updated: false,
          },
        )
      }
    },
    async getStockageCapteurs() {
        let results = [];
        for (let i = 0; i < this.capteur_id.length; i++) {
            const tag_id = this.capteur_id[i];
            const result = await getStockage(tag_id)
            if (result) {
              results.push(result);
            }
        }
        for (let i = 0; i < results.length; i++) {
            let duration = results[i] ? results[i][0].table_size : '';	
            // Affectation de la durée formatée à la propriété 'durée' de l'objet correspondant
            this.capteur[i].stockage = duration;
        }
        this.show = 1;
    },

    async getDurationCapteurs() {
			for (let i = 0; i < this.capteur_id.length; i++) {
				const tag_id = this.capteur_id[i];
        const total_duration = await getDurationCapteurs(tag_id)
				this.capteur[i].durée = await formatDuration(total_duration)
			}
		},

		
    async getDatafor(){
      for(let i = 0; i < this.capteur.length; i++){
        const data = await getDataLastId(this.capteur_id[i]);
        const axe_x = (data[0]?.acc_x_tag?.[0] || 0).toFixed(5);
        const axe_y = (data[0]?.acc_y_tag?.[1] || 0).toFixed(5);
        const axe_z = (data[0]?.acc_z_tag?.[2] || 0).toFixed(5);
        this.capteur[i].last_used = this.formatDate(data[0].received_on);
        this.capteur[i].vibrations = axe_x + '  ,  ' + axe_y + '  ,  ' +  axe_z;
        this.capteur[i].position = data[0]?.pos_y_tag*8 + data[0]?.pos_x_tag + 1;
        const b = data[0].id > this.id_memory[i];
        if(b){
          this.capteur[i].wifi = 'connecté';
          this.capteur[i].updated = true;
        }else{
          this.capteur[i].wifi = 'déconnecté';
          this.capteur[i].updated = false;
        }
        this.id_memory[i] = data[0].id
      }
    },
    async getAllDataCapteurs() {
        
    },
    formatDate(date) {
        return moment(date).format('LL, LT');
    },
    },
    beforeDestroy() {
      clearInterval(this.interval1);
      clearInterval(this.interval2);
    },
}
</script>